// api/services/chatService.js
import axios from "../axios";



const getDefaultCategory = async () => {
  try {
    const response = await axios.get(`/chat/getDefaultCategory`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getCategories = async () => {
  try {
    const response = await axios.get(`/chat/getCategories`);
    return response;
  } catch (error) {
    throw error;
  }
};


const getChats = async () => {
  try {
    const response = await axios.get(`/chat/getCategoriesWithChatDetails`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getCategoriesChatsMessages = async () => {
  try {
    const response = await axios.get(`/chat/getCategoriesChatsMessages`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getChatMessages = async (chatId) => {
  try {
    const response = await axios.get(`/chat/getChatMessages/${chatId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getChatUserInfo = async (chatId) => {
  try {
    const response = await axios.get(`/chat/chatUserInfo/${chatId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getChatUserAssociation = async () => {
  try {
    const response = await axios.get(`/chat/getChatAssociationByUser`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getExistingChat = async (userId, friendId) => {
  try {
    const response = await axios.get(`/chat/existingChat/${userId}/${friendId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const initiateChat = async (body) => {
  try {
    const response = await axios.post(`/chat/initiateChat`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

const removeChat = async (body) => {
  try {
    const response = await axios.delete(`/chat/removeChat/${body.chatId}/${body.chatUserAssociationId}/${body.categoryId}`);
    return response;

  } catch (error) {
    throw error;
  }
};

const createCategory = async (body) => {
  try {
    const response = await axios.post(`/chat/createCategory`, body);
    return response;
  } catch (error) {
    throw error;
  }
}

const changeChatCategory = async (body) => {
  try {
    const response = await axios.post(`/chat/changeChatCategory`, body);
    return response;
  } catch (error) {
    throw error;
  }
}

// Existing function for unread message count with serviceCode
const getUnreadMessageCount = async (body) => {
  try {
    const response = await axios.post(`/chat/getUnreadMessageCount`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

// New function to fetch total unread count (regular + group chats)
const getTotalUnreadCount = async (userId) => {
  try {
    const response = await axios.get(`/chat/unread-count`, { params: { userId } });
    return response;
  } catch (error) {
    throw error;
  }
};

const sendMessage = async (body) => {
  try {
    const response = await axios.post(`/chat/sendMessage`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

const removeUserFromChat = async (body) => {
  try {
    const response = await axios.post(`/chat/removeUserFromChat/`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

const getAllChats = async (userId) => {
  try {
    const response = await axios.get(`/chat/getAllChats/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getSearchCriteriaFromChatDatabase = async (chatCategoryId) => {
  try {
    const response = await axios.get(`/chat/getSearchCriteriaFromChatDatabase`, {
      params: {
        chatCategoryId: chatCategoryId,
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};


const addToChatDataBase = async (body) => {
  try {
    const response = await axios.post(`/chat/addToChatDataBase`, body); // Ensure the correct base URL
    return response;
  } catch (error) {
    throw error;
  }
};

const addUserToCategory = async (body) => {
  try {
    const response = await axios.post('/chat/addUserToCategory', body);
    return response;
  } catch (error) {
    throw error;
  }
};

const chatService = {
  getCategoriesChatsMessages,
  getDefaultCategory,
  getCategories,
  createCategory,
  addUserToCategory,
  getSearchCriteriaFromChatDatabase,
  addToChatDataBase,
  getChats,
  getChatUserAssociation,
  getChatUserInfo,
  getChatMessages,
  getExistingChat,
  initiateChat,  

  changeChatCategory,
  removeChat,

  getUnreadMessageCount, // Existing function
  getTotalUnreadCount,    // Newly added function
  sendMessage,
  removeUserFromChat,
  getAllChats
};

export default chatService;
