import React, { useEffect, useState, useReducer} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

import socket from 'socketio/socket';

import { List, Typography, Box, ListItem, ListItemAvatar, Avatar, ListItemText, ButtonBase } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { 
  updateCategories,

  setSelectedCategoryId,
  setChatSessionId
} from 'app/slices/chat.slice';

import chatService from 'api/services/chatService';

import CategoryList from '../categoryList/CategoryList';
import ChatListItem from './ChatListItem';

import { delay } from 'scripts/delay';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function ChatList({ group, chatTypeMenuVisible }) {
  const isMobile = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [,forceUpdate] = useReducer(x => x + 1, 0);

  const selectedCategoryId = useSelector(state => state.chats.selectedCategoryId);
  const categories = useSelector(state => state.chats.categories);
  const category = useSelector(state => state.chats.categories[selectedCategoryId]);
  const chatId = useSelector(state => state.chats.chatSessionId); 
  const userId = useSelector(state => state.user.userId);
  const chats = useSelector(state => state.chats.chats[selectedCategoryId]);
  const chatUserAssociation = useSelector(state => state.chats.chatUserAssociation);
  
  const handleAddPeopleClick = () => {
    navigate('/chat-search');
  };

  /* Chat list */
  const ChatListComponent = (
    <List 
      className='List'
      sx={isMobile ?
        {
          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',
          mt: 2,
          mx: 1,
          overflowY: 'scroll',
          height: chatTypeMenuVisible ? '78vh' : '80vh',
          '@media (max-height: 870px)': {
            height: '75vh',
          },
          '@media (max-height: 700px)': {
            height: '70vh',
          },
        }
        :
        {
          width: '100%',
        }
      }
    >
      {/* If there exist chat in category */}
      {chats && Object.keys(chats).length  > 0 && Object.values(chats).map(chat => (
          <ChatListItem 
            key={chat._id}
            chat={chat}
            friendId={chatUserAssociation[chat._id].friendId}
          />
      ))}
      {/* If there exist no chat in category */}
      {chats && Object.keys(chats).length === 0 && (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', textAlign: 'center', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'center'}}>
          <Box>
            <Typography variant="body1" color="textSecondary" width='100%'>
              No chats in this category.
            </Typography>
          </Box>
        </Box>
      )}
    </List>
  );

  // Refresh chat list when chat operations happen
  useEffect(() => {
    
  },[chats]);

  // Title and Back button
  const handleBackButton = () => {
    dispatch(setSelectedCategoryId(null));
    // socket.emit('refreshChatSignal',{});
  }
  const TitleNBackButton = (
    <Box
          sx={{ display: "flex", width: "100%", alignItems: "center"}}
        >
          {group && (
            <IconButton onClick={handleBackButton} >
              <ArrowBackIosIcon fontSize='small' sx={{ ml: 1 }} />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ ml: 1 }} fontWeight={'bold'}>
            {category && category.name}
          </Typography>
      </Box>
  );


  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        {/* Add People to Chat Button */}
        <IconButton
          onClick={handleAddPeopleClick}
          sx={{
            position: 'absolute',
            top: -30,
            right: 0,
            m: 1,
            zIndex: 10, // Ensure the button is on top
          }}
        >
          <PersonAddIcon />
        </IconButton>
        <Box
          className="chat-list"
          sx={
            isMobile ?
            { // Mobile layout
              width: '100%',
              height: '100%',
              display: chatId === null ? "visible" : "none"
            }
            :
            { // PC layout
              width: '100%'
            }
          }
        >
          <Box 
            sx={
              isMobile ? 
              { // Mobile layout
                mt: 2, 
                borderRadius: 'var(--border-radius-main)',
                width: '100%', 
              }
              :
              { // PC layout
                mt: 2, 
                borderRadius: 'var(--border-radius-main)',
                overflowY: 'scroll',
                width: '100%', 
                height: chatTypeMenuVisible ? 'calc(85vh - 87px)' : 'calc(85vh - 87px)', 
                '@media (max-height: 650px)': {
                  height: chatTypeMenuVisible ? 'calc(80vh - 87px)' : 'calc(80vh - 87px)'
                },
                '@media (max-height: 508px)': {
                  height: chatTypeMenuVisible ? 'calc(75vh - 87px)' : 'calc(75vh - 87px)'
                },
              }
            }
          >
            {Object.keys(categories).length && selectedCategoryId === null ?
                <>
                </>
              :
                <>
                  {/*TitleNBackButton*/}
                  {ChatListComponent}
                </>
            }
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ChatList;
