// app/slices/chat.slice.js
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  // Chat Categories and Associations
  categories: {},
  chatUserAssociation: {},
  defaultCategory: "",

  // Chat Data
  chats: {}, // { categoryId: { chatId: chatData } }
  lastMessages: {}, // { chatId: lastMessage }
  chatMessages: {}, // { chatId: [message1, message2, ...] }

  // Chat Session States
  selectedCategoryId: null,
  chatSessionId: null,
  friendId: null,
  friendUserInfo: {},

  // Other Shared States
  messageEditMode: false,
  profileDrawerVisible: false,

  // Unread Message Count
  unreadCount: 0, // Total unread messages across all chats
}


const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    // Category Reducers
    addCategory: (state, action) => {
      const category = action.payload;
      const categoryId = category._id;
      state.categories[categoryId] = category;
    },
    updateCategories: (state, action) => {
      const categories = action.payload;
      state.categories = categories;
    },
    updateDefaultCategory: (state, action) => {
      const defaultCategory = action.payload;
      state.defaultCategory = defaultCategory;
    },

    // Chat Reducers
    addNewChat: (state, action) => {
      const { newChatUserAssociation, newChat, newMessage } = action.payload;
      const categoryId = newChatUserAssociation.categoryId;
      const chatId = newChat._id;

      if (!state.chats[categoryId]) {
        state.chats[categoryId] = {};
      }

      state.chatUserAssociation[chatId] = newChatUserAssociation;
      state.chats[categoryId][chatId] = newChat;
      state.lastMessages[chatId] = newMessage;

      // Initialize chatMessages for the new chat
      state.chatMessages[chatId] = [newMessage];
    },
    updateChats: (state, action) => {
      const chats = action.payload;
      state.chats = chats;
    }, 
    deleteChat: (state, action) => {
      const { chatId, categoryId } = action.payload;
      delete state.chats[categoryId][chatId];
      delete state.chatUserAssociation[chatId];
      delete state.lastMessages[chatId];
      delete state.chatMessages[chatId];
    },
    updateChatCategory: (state, action) => {
      const { newChatUserAssociation, oldCategoryId } = action.payload;
      const chatId = newChatUserAssociation.chatId;
      const newCategoryId = newChatUserAssociation.categoryId;

      state.chatUserAssociation[chatId] = newChatUserAssociation;
      
      // Move chat from old category to new category
      const chat = state.chats[oldCategoryId][chatId];
      delete state.chats[oldCategoryId][chatId];
      if (!state.chats[newCategoryId]) {
        state.chats[newCategoryId] = {};
      }
      state.chats[newCategoryId][chatId] = chat;
    },
    updateLastMessages: (state, action) => {
      const lastMessages = action.payload;
      state.lastMessages = lastMessages;
    },
    updateLastMessage: (state, action) => {
      const newLastMessage = action.payload;
      state.lastMessages[newLastMessage.chatId] = newLastMessage;
    },
    
    // Chat & User Association Reducers
    updateChatUserAssociation: (state, action) => {
      const chatUserAssociation = action.payload;
      state.chatUserAssociation = chatUserAssociation;
    },
    updateChatUserInfo: (state, action) => {
      state.chatUserInfo = action.payload;
    },

    // Chat Session State Setters
    setSelectedCategoryId: (state, action) => {
      const categoryId = action.payload;
      state.selectedCategoryId = categoryId;
    },
    setChatSessionId: (state, action) => {
      const chatId = action.payload;
      state.chatSessionId = chatId;
    },
    setFriendId: (state, action) => {
      const friendId = action.payload;
      state.friendId = friendId;
    },
    setFriendUserInfo: (state, action) => {
      const friendUserInfo = action.payload;
      state.friendUserInfo = friendUserInfo;
    },

    // Chat Message Edit Mode Toggle
    setMessageEditMode: (state, action) => {
      const editModeBool = action.payload;
      state.messageEditMode = editModeBool;
    },

    // Profile Drawer Visibility Toggle (Mobile Only)
    setProfileDrawerVisible: (state, action) => {
      const profileDrawerVisible = action.payload;
      state.profileDrawerVisible = profileDrawerVisible;
    },

    // Unread Message Count Reducers
    incrementUnreadCount: (state, action) => {
      const { chatId } = action.payload;
      // Assuming chatUserAssociation[chatId] holds the unread count
      if (state.chatUserAssociation[chatId] && typeof state.chatUserAssociation[chatId].unreadCount === 'number') {
        state.chatUserAssociation[chatId].unreadCount += 1;
      } else {
        if (state.chatUserAssociation[chatId]) {
          state.chatUserAssociation[chatId].unreadCount = 1;
        }
      }
      state.unreadCount += 1;
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    resetUnreadCount: (state) => {
      state.unreadCount = 0;
      // Optionally reset individual chat unread counts
      Object.keys(state.chatUserAssociation).forEach(chatId => {
        if (typeof state.chatUserAssociation[chatId].unreadCount === 'number') {
          state.chatUserAssociation[chatId].unreadCount = 0;
        }
      });
    },

    // **New Reducers for Centralized Chat Management**
    setAllChats: (state, action) => {
      const allChats = action.payload; // Array of chats
      state.chats = {}; // Reset existing chats

      allChats.forEach(chat => {
        const categoryId = chat.categoryId;
        const chatId = chat._id;

        if (!state.chats[categoryId]) {
          state.chats[categoryId] = {};
        }

        state.chats[categoryId][chatId] = chat;
        state.chatUserAssociation[chatId] = chat.userAssociation; // Assuming chat contains userAssociation
        state.lastMessages[chatId] = chat.lastMessage; // Assuming chat contains lastMessage

        // Initialize chatMessages if not already present
        if (!state.chatMessages[chatId]) {
          state.chatMessages[chatId] = chat.messages || []; // Assuming chat contains messages
        }
      });
    },
    addChatMessage: (state, action) => {
      const message = action.payload;
      const { chatId } = message;

      if (!state.chatMessages[chatId]) {
        state.chatMessages[chatId] = [];
      }

      state.chatMessages[chatId].push(message);
      state.lastMessages[chatId] = message;

      // Update unread counts if the message is not from the current user
      if (message.senderId !== state.friendId) {
        if (state.chatUserAssociation[chatId] && typeof state.chatUserAssociation[chatId].unreadCount === 'number') {
          state.chatUserAssociation[chatId].unreadCount += 1;
        } else {
          if (state.chatUserAssociation[chatId]) {
            state.chatUserAssociation[chatId].unreadCount = 1;
          }
        }
        state.unreadCount += 1;
      }
    },
  },
});

export const { 
  addCategory, 
  updateCategories, 
  updateDefaultCategory,  
  
  addNewChat,
  deleteChat,
  updateChatUserAssociation, 
  updateChats, 
  updateLastMessage,
  updateLastMessages,
  updateChatUserInfo,

  setSelectedCategoryId,
  setChatSessionId,
  setFriendId,
  setFriendUserInfo,

  setMessageEditMode,
  setProfileDrawerVisible,

  incrementUnreadCount,

  // New Reducers for Unread Count
  setUnreadCount,
  resetUnreadCount,
  updateChatCategory,
  // New Reducers for Centralized Chat Management
  setAllChats,
  addChatMessage,

} = chatSlice.actions;
export default chatSlice.reducer;
