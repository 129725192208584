import React, { useState } from 'react';
import SearchResults from './SearchResults';
import {
  Container,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import {
  BasicsSearchForm,
  CareerSearchForm,
  PhysicalSearchForm,
  PersonalitySearchForm,
  TheTeaSearchForm,
} from '../forms/createFormComponent';
import userService from '../../api/services/userService';

import { isMobile } from '../../scripts/mobileCheck';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: grey[900], // Style for active tab text
          },
        },
      },
    },
  },
});

////////////////////////////////////////////////////////////////////////////////////
// This is the Search component
////////////////////////////////////////////////////////////////////////////////

const Search = ({ results, setResults }) => {
  // Initialize searchCriteria state with empty objects for each category
  const [searchCriteria, setSearchCriteria] = useState({
    prefer: {}, // Criteria for preferred attributes
    require: {}, // Criteria for required attributes
    exclude: {}, // Criteria for attributes to exclude
  });

  // State to track the currently selected main tab index (Preference, Require, Exclude)
  const [mainTabValue, setMainTabValue] = useState(0);
  // Array of main category names corresponding to tabs
  const mainCategories = ['Prefer', 'Require', 'Exclude'];

  // State to track the currently selected sub-tab index (Basics, Career, etc.)
  const [tabValue, setTabValue] = useState(0);

  // State to control the visibility of SearchResults
  const [showResults, setShowResults] = useState(false);

  // Configuration for sub-forms corresponding to each sub-tab
  const formsConfig = [
    { title: 'Basics', component: BasicsSearchForm, dataKey: 'basics' },
    { title: 'Career', component: CareerSearchForm, dataKey: 'career' },
    { title: 'Physical', component: PhysicalSearchForm, dataKey: 'physical' },
    { title: 'Personality', component: PersonalitySearchForm, dataKey: 'personality' },
    { title: 'The Tea', component: TheTeaSearchForm, dataKey: 'theTea' },
  ];

  /**
   * Updates the search criteria state based on category and section.
   * @param {string} category - The main category ('prefer', 'require', 'exclude').
   * @param {string} section - The sub-section within the category (e.g., 'basics').
   * @param {object} data - The data to update for the specific section.
   */
  const updateSearchCriteria = (category, section, data) => {
    // Log the category and section being updated for debugging purposes
    console.log(`Updating search criteria for category: ${category}, section: ${section}`);
    // Log the data received to verify the input
    console.log('Data received:', data);

    // Update the searchCriteria state immutably
    setSearchCriteria((prev) => ({
      ...prev, // Spread the previous state to retain other categories
      [category]: { // Update the specific category
        ...prev[category], // Spread the previous category state to retain other sections
        [section]: data, // Update the specific section with new data
      },
    }));
  };

  const handleSubmit = () => {
    userService
      .searchUserInfos(searchCriteria)
      .then((res) => {
        setResults(res);
        console.log('Search criteria:', searchCriteria);
        console.log('Results:', res);

        // Set showResults to true to trigger rendering
        setShowResults(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setTabValue(0); // Reset sub-tab when main tab changes
  };

  // Determine if the device is mobile once to avoid multiple calls
  const mobile = isMobile();

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="search"
        sx={
          mobile
            ? {
                // Mobile layout
                width: '100%',
                border: '0px solid var(--color-accent)',
              }
            : {
                // PC layout
                width: '450px',
                height: '100%',
                overflowY: 'scroll',
                border: '2px solid var(--color-accent)',
                borderRadius: 'var(--border-radius-main)',
              }
        }
      >
        <Box mx={2} my={2} mb={5}>
          {/* Main Category Tabs */}
          <Tabs
            value={mainTabValue}
            onChange={handleMainTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              '.MuiTab-textColorPrimary': { color: grey[900] },
              '.MuiTabs-indicator': { backgroundColor: grey[900] },
            }}
          >
            {mainCategories.map((category, index) => (
              <Tab label={category} key={index} />
            ))}
          </Tabs>

          {/* Conditionally render the search forms */}
          {(!mobile || (mobile && !showResults)) && (
            <>
              {/* Render forms under the selected main category */}
              {mainCategories.map((category, mainIndex) => (
                <div
                  role="tabpanel"
                  hidden={mainTabValue !== mainIndex}
                  id={`main-tabpanel-${mainIndex}`}
                  aria-labelledby={`main-tab-${mainIndex}`}
                  key={mainIndex}
                >
                  {mainTabValue === mainIndex && (
                    <Box>
                      {/* Sub-tabs for forms */}
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto" // 'auto' will only show scroll buttons when needed
                        allowScrollButtonsMobile
                        textColor="primary"
                        indicatorColor="primary"
                        sx={{
                          '.MuiTab-textColorPrimary': { color: grey[900] },
                          '.MuiTabs-indicator': { backgroundColor: grey[900] },
                        }}
                      >
                        {formsConfig.map((form, index) => (
                          <Tab label={form.title} sx={{ textTransform: 'none' }} key={index} />
                        ))}
                      </Tabs>

                      {formsConfig.map((form, index) => (
                        <div
                          role="tabpanel"
                          hidden={tabValue !== index}
                          id={`tabpanel-${index}`}
                          aria-labelledby={`tab-${index}`}
                          key={index}
                        >
                          {tabValue === index && (
                            <Box p={3}>
                              <form.component
                                initialValues={
                                  searchCriteria[mainCategories[mainTabValue].toLowerCase()] &&
                                  searchCriteria[mainCategories[mainTabValue].toLowerCase()][form.dataKey]
                                }
                                onSubmit={(data) =>
                                  updateSearchCriteria(
                                    mainCategories[mainTabValue].toLowerCase(),
                                    form.dataKey,
                                    data
                                  )
                                }
                                onValuesChange={(updatedValues) => {
                                  updateSearchCriteria(
                                    mainCategories[mainTabValue].toLowerCase(),
                                    form.dataKey,
                                    updatedValues
                                  );
                                }}
                                isEditMode={false}
                                margin={'dense'}
                              />
                            </Box>
                          )}
                        </div>
                      ))}
                    </Box>
                  )}
                </div>
              ))}

              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: 'var(--color-accent)',
                    borderRadius: '9999px',
                    textTransform: 'none',
                    transition: '0.2s',
                    color: 'var(--color-text-invert)',
                    opacity: 1,
                    '&:hover': {
                      backgroundColor: 'var(--color-accent)',
                      color: 'var(--color-text-invert)',
                      opacity: 0.7,
                    },
                  }}
                >
                  Search
                </Button>
              </Box>
            </>
          )}

          {/* Conditionally render SearchResults based on showResults and device type */}
          {showResults && (
            <Box mt={4}>
              <SearchResults 
                results={results ? results : []} 
                setResults={setResults} 
                setShowResults={setShowResults} // Pass setShowResults here
              />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Search;
