// React and Redux essentials
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// Day.js
import dayjs from 'dayjs';

// Redux state management
import { updateUser } from '../../app/slices/user.slice';
import { 
  updateChats, 
  updateChatUserAssociation, 
  addNewChat, 
  setChatSessionId 
} from '../../app/slices/chat.slice';

// MUI components
import { 
  Avatar, 
  Typography, 
  Box, IconButton, 
  Button, 
  TextField, 
  Modal, 
  CircularProgress 
} from '@mui/material';

// MUI style
import { styled } from '@mui/material/styles';

// MUI icons
import SendIcon from '@mui/icons-material/Send';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';

// Info section components
import BasicsSection from './BasicsSection';
import CareerSection from './CareerSection';
import PhysicalSection from './PhysicalSection';
import PersonalitySection from './PersonalitySection';
import TheTeaSection from './TheTeaSection';
import ProfileSection from './ProfileSection';

// Backend service call
import userService from '../../api/services/userService';
import chatService from '../../api/services/chatService';
import groupChatService from 'api/services/groupchatService';

// Socket.IO
import socket from '../../socketio/socket';

// Components
import AddToGroup from './dialogs/AddToGroup';
// import AddToChat from './dialogs/AddToChat';


const ProfileContainer = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledAvatar = styled(Avatar)({
  width: '120px',
  height: '120px',
  marginBottom: '1em',
  border: '4px solid white',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)'
});

const UserInfo = styled(Box)({
  textAlign: 'center',
  marginBottom: '1em'
});

const MessageFieldContainer = styled(Box)({
  display: 'flex',
  width: '85%',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

// Updated styles to fit images within the viewport
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',             // Adjust width automatically to fit content
  height: 'auto',            // Adjust height automatically to fit content
  maxHeight: '90vh',         // Limit max height to viewport height
  maxWidth: '90vw',          // Limit max width to viewport width
  bgcolor: 'transparent',    // Set background color to transparent to remove white space
  boxShadow: 'none',         // Remove box shadow to eliminate extra spacing
  overflow: 'hidden',        // Prevent any overflow within the modal
  display: 'flex',           // Use flex to center the content
  justifyContent: 'center',
  alignItems: 'center',
};

const modalImageStyle = {
  maxHeight: '100vh',         // Image will fit within the container's height
  maxWidth: '100vw',          // Image will fit within the container's width
  display: 'block',
  objectFit: 'contain',      // Ensures the image scales down and maintains its aspect ratio
  padding: 0,
  margin: 0,
};

export default function MemberProfile(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useSelector(state => state.global.isMobile);

  const userId = useSelector(state => state.user.userId);
  const userFirstName = useSelector(state => state.user.name);
  const categoryId = useSelector(state => state.chats.defaultCategory._id);
  const friendId = props.userId;
  const { images, name, description, basics, career, physical, personality, theTea, chatPageLayout } = props;

  const [message, setMessage] = useState('');
  const [existingChat, setExistingChat] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Image enlarge handler
  const handleOpenModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  // Backend interaction
  useEffect(() => {
    chatService.getExistingChat(userId, friendId).then((res) => {
      setExistingChat(res.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    const createNewChat = newChat => {
      setExistingChat(newChat.newChat);
      if (newChat.newChatUserAssociation.userId === userId) {}
    };

    socket.on('newChat', createNewChat);

    return () => {
      socket.off('newChat');
    };
  }, [socket]);

  // Chat button / Message input 
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleMessageSend = () => {
    chatService.getExistingChat(userId, friendId).then((res) => {
      if (isEmpty(res.data)) {
        const chatName = userFirstName + ", " + name;
        const content = message;
        const body = { userId, categoryId, friendId, chatName, content };
        chatService.initiateChat(body).then((res) => {
          dispatch(setChatSessionId(res.data._id));
          navigate('/chats');
        });
      }
    }).catch(err => {
      console.log(err);
    });
  };

  // Age calculation handler
  const calculateAge = (dateOfBirth) => {
    const dob = dayjs(dateOfBirth);
    const now = dayjs();
    return now.diff(dob, 'year');
  };

  // Member pictures 
  const memberPictures = (
    <Box 
      className='member-profile-images'
      mx={isMobile ? 3 : 3}
      sx={
        isMobile || chatPageLayout ?
        { // Mobile layout
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,

          width: '90%',
          height: images && images.length && images.length <= 1 ? '300px' : '400px',
          overflowY: 'scroll',
        }
        :
        { // PC layout
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,

          height: images.length <= 2 ? '300px' : '380px',
          overflow: 'scroll'
        }
      }
    >
      {images && images.length > 0 && images.map((image, index) => (
        <Box key={index} position="relative" onClick={() => handleOpenModal(image)}>
          <Avatar
            src={image.mediaUrl}
            alt={`Profile Media ${index}`}
            variant='rounded'
            sx={{ width: images.length <= 1 ? '250px' : '170px', height: images.length <= 1 ? '250px' : '170px', cursor: 'pointer', borderRadius: 'var(--border-radius-box)' }}
          />
        </Box>
      ))}

      {!images.length && (
        <Box position="relative">
          <Avatar
            variant='rounded'
            sx={{ width: images.length <= 1 ? '250px' : '170px', height: images.length <= 1 ? '250px' : '170px', cursor: 'pointer', borderRadius: 'var(--border-radius-box)' }}
          />
        </Box>
      )}
    </Box>
  );

  // Member picture enlarge
  const memberPicturePopup = (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          component="img"
          sx={modalImageStyle}  // Ensures image fits without scrolling
          src={selectedImage && selectedImage.mediaUrl}
          alt="User picture"
        />
      </Box>
    </Modal>
  );

  // Member name and description
  const memberDesc = (
    <UserInfo 
      className='member-profile-name'
      mt={3} 
      mx={3}
    >
      <Typography variant="h4" component="div">{name}, <span style={{fontWeight: 300}}>{calculateAge(basics.dateOfBirth)}</span></Typography>
      <Typography variant="body2" style={{ marginTop: '1em '}}>{description}</Typography>
    </UserInfo>
  );

  // Chat button / Message input box
  const memberChatButton = (
    !chatPageLayout ?
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          className='member-profile-chat'
          mx={3}
          sx={
            isMobile || chatPageLayout ?
            {
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '5em'
            }
            :
            {
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '0px'
            }
          }
        >
          {isEmpty(existingChat) && (
            <MessageFieldContainer
              mx={2}
              sx={
                isMobile || chatPageLayout ?
                { // Mobile layout
                  width: '90%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }
                :
                { // PC layout
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }
              }
            >
              <TextField
                fullWidth
                multiline
                InputProps={{ sx: { borderRadius: '9999px' } }}
                minRows={1}
                maxRows={5}
                placeholder="Send a message..."
                variant="outlined"
                size="small"
                mr={1}
                value={message}
                onChange={handleMessageChange}
              />
              <IconButton
                sx={{ color: 'var(--color-accent)', paddingRight: 0 }}
                onClick={() => handleMessageSend()}
              >
                <ArrowCircleRightIcon 
                  fontSize="large"
                />
              </IconButton>
            </MessageFieldContainer>
          )}

          {!isEmpty(existingChat) && (
            <Button 
              sx={{ 
                opacity: 1,
                textTransform: "none",
                transition: '0.2s',
                color: 'var(--color-text-invert)',
                borderRadius: 'var(--border-radius-box)',
                borderColor: 'var(--color-accent)',
                backgroundColor: 'var(--color-accent)',
                '&:hover': {
                  borderRadius: 'var(--border-radius-box)',
                  borderColor: 'var(--color-accent)',
                  backgroundColor: 'var(--color-accent)',
                  opacity: 0.7
                },
                width: '130px',
                height: '40px',
              }}
              size='small'
              variant="outlined"
              component={Link}
              to={`/chat/${existingChat._id}`}
            >
              <QuestionAnswerRoundedIcon fontSize='medium'/>&nbsp;&nbsp;Go to Chat
            </Button>
          )}
        </Box>
        <Box mt={2} sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <AddToGroup friendId={friendId}/>
        </Box>
      </Box>
    :
    <Box mb={2}>
    </Box>
  );

  // Member infos/attributes
  const memberAttr = (
    <Box
      className='member-profile-info'
      sx={
        isMobile || chatPageLayout ?
        { // Mobile
          width: '95%',
          border: !chatPageLayout ? '2px solid var(--color-accent)' : '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',
          overflowY: 'scroll'
        }
        :
        { // PC
          height: '60vh',
          width: '450px',
          border: '2px solid #000',
          borderRadius: 'var(--border-radius-main)',
          overflowY: 'scroll'
        }
      }
    >
      <Box>&nbsp;</Box> {/*Extra margin on top*/}
      <BasicsSection basics={basics} self={false} />
      <CareerSection career={career} self={false} />
      <PhysicalSection physical={physical} self={false} />
      <PersonalitySection personality={personality} self={false} />
      <TheTeaSection theTea={theTea} self={false} />
    </Box>
  );

  // Loading circle
  const loadingCircle = (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );

  return (
    <>
    {isLoading ? (
      <>{loadingCircle}</>
    ) : (
      <ProfileContainer 
        className='member-profile'
        sx={
          isMobile || chatPageLayout ?
          { // Mobile layout
            mb: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }
          :
          { // PC layout
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '50px',
          }
        }
      >
        {/*Container for member profile picture, name, and description*/}
        <Box
          className='member-profile-summary'
          sx={
            isMobile || chatPageLayout ?
            { // Mobile layout
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              width: '100%'
            }
            :
            { // PC layout
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '450px'
            }
          }
        >
          {/*Member images*/}
          {memberPictures}

          {/*Member image enlarge popup*/}
          {memberPicturePopup}

          {/*Member name and description*/}
          {memberDesc}

          {/*Chat button / Message input box */}
          {memberChatButton}
          
        </Box>
        
        {/*Member infos*/}
        {memberAttr}

      </ProfileContainer>
      )}
    </>
  );
}
